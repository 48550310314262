import React, { Suspense, useContext } from "react";
import BlockUi from "react-block-ui";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContext, AppDataProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import { configureStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import './initFA';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

const ResidentsApp = () => {
  const { isLoadingLease, isLoadingLeases } = useContext(AppContext);

  return (
    <BlockUi
      tag="div"
      className="h-100"
      blocking={isLoadingLease || isLoadingLeases}
      loader={<div className="loading" />}
      renderChildren={false}
    >
      <App />
    </BlockUi>
  );
};

ReactDOM.render(
  <Provider store={configureStore()}>
    <AuthProvider>
      <AppDataProvider>
        <Suspense fallback={<div className="loading" />}>
          <ResidentsApp />
        </Suspense>
      </AppDataProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
