import { getUserGuideUrl } from '../helpers/Utils';

export const smartHomeMenu = {
  index:2,
  id: 'secondmenu',
  icon: 'iconsminds-cpu',
  label: 'menu.smart-home',
  to: '/app/smart-home',
  subs: [
    {
      icon: 'simple-icon-bulb',
      label: 'menu.devices',
      to: '/app/smart-home/devices',
    },
    {
      icon: 'simple-icon-key',
      label: 'menu.access-control',
      to: '/app/smart-home/access',
    },
    {
      icon: 'simple-icon-paper-plane',
      label: 'menu.events',
      to: '/app/smart-home/events',
    },
    {
      icon: 'simple-icon-settings',
      label: 'menu.home-settings',
      to: '/app/smart-home/home-settings',
    },
    {
      icon: 'simple-icon-book-open',
      label: 'menu.user-guide',
      to: getUserGuideUrl(),
      newWindow: true,
    }
   ],
 };

export const dashboardMenu = {
  index:1,
  id: 'dashboard',
  icon: 'iconsminds-digital-drawing',
  label: 'menu.dashboard',
  to: '/app/dashboard'
};

export const chatMenu =  {
  index:3,
  id: 'chats',
  icon: 'simple-icon-bubbles',
  label: 'menu.chat',
  to: '/app/inbox',
};

export const billingMenu =  {
  index:4,
  id: 'billing',
  icon: 'iconsminds-cash-register-2',
  label: 'menu.billing',
  to: '/app/billing',
  subs: [
    {
      icon: 'iconsminds-money-bag',
      //icon: 'iconsminds-financial',
      label: 'menu.balance',
      to: '/app/billing/balance',
    },
    {
      icon: 'iconsminds-financial',
      label: 'menu.one-time-payment',
      to: '/app/billing/one-time',
    },
    {
      icon: 'iconsminds-testimonal',
      label: 'menu.invoice-history',
      to: '/app/billing/invoices',
    },
    {
      icon: 'iconsminds-dollar',
      label: 'menu.payment-history',
      to: '/app/billing/payments',
    },
    {
      icon: 'iconsminds-wallet',
      label: 'menu.payment-methods',
      to: '/app/billing/payment-methods',
    }
  ],
};

export const servicesMenu = {
  index:5,
  id: 'services',
  icon: 'iconsminds-business-man',
  label: 'menu.services',
  to: '/app/services',
  subs: [
    {
      icon: 'simple-icon-wrench',
      label: 'menu.work-orders',
      to: '/app/services/work-orders',
    },
    {
      icon: 'simple-icon-briefcase',
      label: 'menu.lease-details',
      to: '/app/services/lease-details',
    },
  ]
};

export const applicationsMenu =  {
  index:7,
  id: 'applications',
  icon: 'iconsminds-testimonal',
  label: 'menu.applications',
  to: '/app/apply',
};

const data = [
   {
    index:6,
    id: 'tours',
    icon: 'iconsminds-footprint-2',
    label: 'menu.tours',
    to: '/app/tours',
  },
  {
    index:8,
    id: 'account',
    icon: 'iconsminds-male',
    label: 'menu.account',
    to: '/app/account',

  },
];
export default data;
