import { defaultDirection } from "../constants/defaultValues";
import { BASE_URL, BEHOME_MEDIA_URL } from "../constants/services";
import moment from "moment-timezone";
import parse from "html-react-parser";

export const safeParse = (text) => {
  if (!text) return;
  return parse(text.replace(/\&quot;/g, '"'));
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const getSubdomain = () => {
  const full = window.location.host.split(":")[0]; // subdomain.domain.com
  const parts = full.split(".");
  return parts[0];
};

export const getCustomComponentSet = () => {
  const subdomain = getSubdomain();

  const map = {
    "alexander":"tricon-residential",
    "alafaya":"tricon-residential",
    "thegrandcypresscove":"tricon-residential",
    "sancerresandlake":"tricon-residential",
    "boardwalk":"tricon-residential",
    "terrastonetravesia":"tricon-residential",
    "carrickbend":"tricon-residential",
     "citynorth":"tricon-residential",
     "copperfield":"tricon-residential",
     "fallscopperlake":"tricon-residential",
     "fallseaglecreek":"tricon-residential",
     "liveatavora":"tricon-residential",
     "palmvalley":"tricon-residential",
    // "progress":"tricon-residential",
     "pureliving":"tricon-residential",
     "sohoparkway":"tricon-residential",
     "sorelle":"tricon-residential",
    "southblvd":"tricon-residential",
    "theallure":"tricon-residential",
    "thecallie":"tricon-residential",
    "theviews":"tricon-residential",
    "thevillage":"tricon-residential",
    // "tricon":"tricon-residential",
    // "triconmf":"tricon-residential",
    "verano":"tricon-residential",
     "yorktowncrossing":"tricon-residential",
    // "tricon-residential":"tricon-residential"
  }

  return map[subdomain];
}

export const getSetup = async () => {
  const subdomain = getSubdomain();
  
  if (localStorage.getItem(`setup-${subdomain}`)) {
    const localSetup = localStorage.getItem(`setup-${subdomain}`);
    return JSON.parse(localSetup);
  } else {
    const response = await fetch(
      BASE_URL + "/api/v1/setup?domain=" + subdomain
    );
    const setup = await response.json();
    if (setup.domain) {
      localStorage.setItem(`setup-${subdomain}`, JSON.stringify(setup));
      return setup;
    }
    return {};
  }
};

export const getLocalSetup = () => {
  const subdomain = getSubdomain();
  if (localStorage.getItem(`setup-${subdomain}`)) {
    const localSetup = localStorage.getItem(`setup-${subdomain}`);
    return JSON.parse(localSetup);
  }else{
    return {};
  }
};

export const cleanLocalSetup = () => { 
  const subdomain = getSubdomain();
  localStorage.removeItem(`setup-${subdomain}`);
};

export const getUserGuideUrl = () => {
  const subdomain = getSubdomain();
  return BEHOME_MEDIA_URL + "/manuals/" + subdomain + "/" + subdomain + ".pdf";
};

export const removeImgPrefix = (string) => {
  return string.split("base64,")[1];
};

export const getBase64 = (file, onOk, onError) => {
  if (!file) return;
  var img = null;
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img = reader.result;
    if (img.startsWith("data:image")) {
      onOk(img);
    } else {
      onError("Invalid file format");
    }
  };
  reader.onerror = function (error) {
    onError("Error uploading file");
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const toLocalDate = (utcDate) => {
  const localDate = new Date(utcDate);
  const offset = localDate.getTimezoneOffset();
  return moment(localDate.getTime() + offset * 60 * 1000);
};

export const timeout = async (ms) => {
  return new Promise((res) => setTimeout(res, ms));
};

export const twoDigits = (number) => {
  let numberStr = number.toString();
  if (numberStr.length < 2) {
    number = "0" + number;
  }
  return number;
};

export const isExpired = (expirationYear, expirationMonth) => {
  var exp = moment(expirationYear + "/" + expirationMonth, "YYYY/M");
  var today = moment();
  return today >= exp.add(1, "months");
};


export const hasValidCreditCard = (user) => {
  var result = false;
  if (user && user.paymentMethods && user.paymentMethods.length > 0) {
    let i = 0;
    while (i < user.paymentMethods.length && !result) {
      let pm = user.paymentMethods[i];
      console.log("PM", pm);
      if (!isExpired(pm.expirationYear, pm.expirationMonth)) {
        result = true;
      }
      i++;
    }
    return result;
  };
};

export const hasPaymentMethodActive = (user) => {
  let count = 0;
  if (user.paymentMethods){
    const pmsFiltered = user.paymentMethods.filter(pm => pm.status === "ACTIVE");
    count = pmsFiltered.length;
  }
  return (count > 0);
};


