import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_ENABLE_SMART_HOME,
  MENU_ENABLE_DASHBOARD,
  MENU_ENABLE_CHAT,
  MENU_ENABLE_BILLING,
  MENU_ENABLE_APPLICATIONS,
  MENU_ENABLE_SERVICES
} from "../actions";

import menuItems, { chatMenu, billingMenu, applicationsMenu } from "../../constants/menu"; 
import { smartHomeMenu, dashboardMenu, servicesMenu } from "../../constants/menu"; 

import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "../../constants/defaultValues";
import _ from "lodash";

const INIT_STATE = {
  items: menuItems,
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === "menu-default", // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export default (state = INIT_STATE, action) => {
  let items = state.items;
  switch (action.type) {
    
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return { ...state, selectedMenuHasSubItems: action.payload };

    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };
    
    case MENU_ENABLE_DASHBOARD:
      
      if(!_.find(items,{index:dashboardMenu.index})) 
        items = [dashboardMenu, ...items];
      
      return {
        ...state,
        items: items.sort((i1, i2)=> i1.index - i2.index)
      };

    case MENU_ENABLE_BILLING:
      if(!_.find(items,{index:billingMenu.index})) 
        items = [billingMenu, ...items];  

      return {
        ...state,
        items: items.sort((i1, i2)=> i1.index - i2.index)
      };

    case MENU_ENABLE_CHAT:
      if(!_.find(items,{index:chatMenu.index})) 
        items = [chatMenu, ...items];  

      return {
        ...state,
        items: items.sort((i1, i2)=> i1.index - i2.index)
      };
    
    case MENU_ENABLE_SERVICES:
        if(!_.find(items,{index:servicesMenu.index})) 
          items = [servicesMenu, ...items];  
  
        return {
          ...state,
          items: items.sort((i1, i2)=> i1.index - i2.index)
        };

    case MENU_ENABLE_APPLICATIONS:
          if(!_.find(items,{index:applicationsMenu.index})) 
            items = [applicationsMenu, ...items];  
    
          return {
            ...state,
            items: items.sort((i1, i2)=> i1.index - i2.index)
          };


    case MENU_ENABLE_SMART_HOME:
    
      if(!_.find(items,{index:smartHomeMenu.index})) 
        items = [smartHomeMenu, ...items];  

      return {
        ...state,
        items: items.sort((i1, i2)=> i1.index - i2.index)
      };

    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CONTAINER_ADD_CLASSNAME:
      return { ...state, containerClassnames: action.payload };

    case MENU_CHANGE_DEFAULT_CLASSES:
      return { ...state, containerClassnames: action.payload };

    default:
      return { ...state };
  }
};
