

//Stripe
// Key test sandbox
//export const STRIPE_PK = "pk_test_51H2bODI7Mx4gzQe3DXxpk572S8TblmnuSiaTV3B6wBr2c6GAS66JwKIjSix3AJr8aSCUPZIHaz4XM5Y6c6PANBd300PBQM7sdE";
// Key test Martin
export const STRIPE_PK = "pk_test_51IWM4jL0nxaTPUagrxSIi5SQ6NZxMftlxLJTvcWlA5puSsrjRs9jdRa0mjYxaZNaeUju9cJpNkzcpZexMWSDuIbq00t4rJ8ndL";

//CheckpointId Onboarding
export const CHECKPOINT_URL = "https://onboarding.checkpointid.com";
export const CHECKPOINT_TOKEN = "174b7a01-b8ed-42fa-477a-08d84933c1ad"; //*.behome247.com
export const CHECKPOINT_V2_LK = "ew0KICAicGRmNDE3a2V5IjogImY4cCtrWGJOaXUybUhoeU9SUlRIc3U0TVBnY0l0d2FxbWgyS0loTHZhRVNSWmY2SFA3R28rSmJVdEdmWXR6dmZjTTFsVGMxeEZiS1dNNUR3eTJBeGtvMUFlWmNJUVc5VzlFd3hIbUhCczR1UVVFK1NOMzN6QmpwUHBVQUtlcExGR3RuZFBpc3liTHB6ZFlQdkJNWVJGazBDbndhWm12Mkdrb2R4TkVUUWdobz0iLA0KICAiaW1hZ2VQcm9jZXNzaW5nS2V5IjogIm94OHErL3pkRUFwYVhMaXRhNU1tMFNVajQ3MExheWV2QlB6V3dmdWpMQzBXZXRyc0h3aU1lc0JhT2xjUDBtRllmVUFacncxdzF0WExHaEVTZUsxN2RrRlBaYkFKN3hqUkFRUjE3K0dqQ2VETzYxdkdYVGVscnNlRUx0b3Vxd3V0d3pvYmhmV1hzTi9sYnVoMmJ3WFdJN0hMbGhXYUFpMWtlVUxUbS9vb1R1WT0iLA0KICAidHJhY2tTdHJpbmdQYXJzZXJLZXkiOiAiTGVGNGpSTGxRdVJrSW9hWG5pQzFuN0t1anhRdFN4dERsaVZub08wT3pJakw2S0s5UHp4REhiTlQvTkU2UjduSXNEUXJrMkxTUGJvb1V4Nk95M1dJVHFneEFib2dNRXpGd3MvM3pNOTlSa0l5ZmVHVUFpbUVCdEdDUFVnaDF4S0tZVnpqbzc0SzVTdyt2cHdMYlJhcXcvemlSa3U3VTZtZHZndUlkdHVURzFvPSIsDQogICJjb21tb25MaWNlbnNlS2V5IjogImM1MU8wT0lRbE4xL01GWUJFSmNySzFHb3k5c2dQYmVWRTZQNllzd2tKQlpKSWw5RktGaU5HZTBmZEFWN1ZBSFlrN3BKYVJIOEdWSGZZV0ovbG1Ib3piZEdidkxsZDRiNFluSmdQQjhWbVJoRFhMeFRmbTQrenpqTFp0R0NiTEMxYUZ0QWpqbmI4NmhqZXdXQTVMMndFU2lZaGE3YmM3UWYrVjcxLzlhbkRTaz0iLA0KICAiaU9TTVJaRGV0ZWN0b3JLZXkiOiAicWlVc0RSZytwT05sWUdBbkVOZDE4TEIvV1haVXA1NUdBRER4UmFrK2g4UmYvSWY5Q01uaTJrN2dESFZlQTZaWm54Nlk0MmMzWW1UWlNNNmE3c1I2bVlxNGhNSFRIMXZqcUNHV0t1WjV3VGVwbmNITHNpVnFBMVVJNWVuc2p0TXZBZzdjSTBtQWFGWVdSRWQvbjJsRExPV1lnWVVDc2VENTd2Z2V1THpqRzVFPSIsDQogICJpT1NQREZEZXRlY3RvcktleSI6ICIqSy5MYkllTGhKb0ttS2VOMkk0SjdBLkxjSG9DbUJtQSINCn0=";
//export const CHECKPOINT_V2_LK = "ew0KICAicGRmNDE3a2V5IjogInBocThUdUFGUk5sWDhIYzFHRlViZmxkbStULzVMS3JrRjFkdytqUHd0YXJLeUhEUUJHSHhidk8wVjlhTmx1RVdTOGQzUGFxTFNRUTMvTXBaa1hORWdhekcwOHFHa2JZbE82K1ZxVEhUaVVtekdiS1Uyd1Q5Tlozdkx2OW1qZFR3N3ErTTRRQWVpT1YwR0NoRjUzZWJ6YTJzYlNseE5WcEZFVDFwSHZkclRXaz0iLA0KICAiaW1hZ2VQcm9jZXNzaW5nS2V5IjogImoza1hneFkwaXNnVEpCUFZQNjlsNEo1WlM1dE50VFNYNU5FQ1hNVm1oazlmckdWMHA2WHVCVXZMK0Vhd0l5dFNyV3ZzeS9xa2FMakVlMG80WDNEMElFNGFsQ05aUkR0eEVjUVltcmFhZ01mWnpwc1UrM1k5QjZPd2lscVRrWGhuT1VqUW5uWlJTQ0FPZ2xuRHpObzd1a1Q5ME5tc1lZZmVINkErbEZPQ1Yzdz0iLA0KICAidHJhY2tTdHJpbmdQYXJzZXJLZXkiOiAiV1EraGtTRkVFZmZRdWFXL0tGeUFhRWE5cUpoaDVWUExKRVI4Rk1lUSswWnBIVTB5S3RjUXgxT3FscWVCbjdhRzN3RmVjMkZ4VWNiWDM4YVdYYUcxMkNPVVI0SC9zYmZSZG9SbnJ6K1ZoRTFwVDNXVmJJUXNqd0tqZXRKY0wrK2NvYXBjRGtKR0RaWHV0b0RVVDNHM0xadFJiNHk2M2JCcUROcmhtdmcxUWNnPSIsDQogICJjb21tb25MaWNlbnNlS2V5IjogImVZeGs2bUlCbkFBY1N5UXRBT0V4QkVrWnBlVVNHZTY0OTcxcW5Ka3Y1dHo4bjU2bUdTZ3cyaHNqUzhGREY1ZVRYWUJXMjlFVndoSnpYVGtxQXRwVTMvb3NtTFQ2T3NCelEyaXRXWnJvMlU5bm95dmFPQ2k0dll0TGF4N1hzbE8xWHEwT3ZydVRUTGpoZ0tBQ2dRU1V2MTRJSzdwWUdEc3psK2syTXg2Q0w5OD0iLA0KICAiaU9TTVJaRGV0ZWN0b3JLZXkiOiAic1duMzRVaWxnSXVOUi91ZkQvOWxxTDhUODZ6VUhxU0lQQmdDNU1wU2xGeEZSazhpVUNydmh2eDk0cGxhdUJ5MkFGNTdxTFY4Zk1vcWgybytwQ1BDUGp5V0hpSzI1RHlKN05hdVZHQ1hNaVlGUnFqS1NaRytBdVFXeVNWQVpmSGZ4WE1aSXhnSHJJRjQ5RnQ5cGVXWHczT3pPTk82cmgyMEhTekYxUi9tTEpRPSIsDQogICJpT1NQREZEZXRlY3RvcktleSI6ICJsTW9QY09hTGxQaEtvSnNOdEJpSGFObkN0TGxLYU50SiINCn0=";
//export const CHECKPOINT_TOKEN = "189423f0-bc0f-4ab4-a66f-08d8492261b3"; //ngrok
//export const CHECKPOINT_TOKEN = "cb1d0263-cb76-4e3d-62fd-08d84566937c"; //*.localhost
//export const CHECKPOINT_TOKEN = "f0cbf665-2c28-44d7-f09e-08d84de01022" //behome.behome247

//Checkpoint DVS
export const CHECKPOINT_DVS_URL = "https://dvs.checkpointid.com/api";
export const CHECKPOINT_PK = "pk_897068ef-a11b-42d9-8a03-a90bb53f41bc"; 

//BH Backend
export const BASE_URL = process.env.REACT_APP_API_BASE || "https://mf-prod.behome247.com";
export const BEHOME_URL = BASE_URL + "/api/v1"; 
export const BEHOME_URL_V2 = BASE_URL + "/api/v2"; 
export const BEHOME_MEDIA_URL = "https://behome247-mf.s3-us-west-2.amazonaws.com";

const DRIVER_LICENSE = 1;
const PASSPORT = 2;
const PASSPORT_CARD = 3;
const GREEN_CARD = 6;
const INTERNATIONAL = 7;

export const DocumentType = {
  DRIVER_LICENSE,
  PASSPORT,
  PASSPORT_CARD,
  GREEN_CARD,
  INTERNATIONAL
};

const OK = 0;
const FAKE = 1;
const DATA_ERROR = 2;
const SERVER_ERROR = 3;

export const DocumentStatus = {
	OK,
	FAKE,
	DATA_ERROR,
	SERVER_ERROR
};
