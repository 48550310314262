/* eslint-disable global-require */
import React from 'react';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
  themeRadiusStorageKey,
  defaultRadius
} from './constants/defaultValues';
import TagManager from 'react-gtm-module'

import { getSetup, getSubdomain } from './helpers/Utils';

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const radius = isMultiColorActive && localStorage.getItem(themeRadiusStorageKey) 
                ? localStorage.getItem(themeRadiusStorageKey) 
                : defaultRadius;

if (radius === 'flat') {
  document.body.classList.remove('rounded');
} else {
  document.body.classList.add('rounded');
}  
localStorage.setItem(themeRadiusStorageKey, radius); 

const setGTM = (setup) => {

  if(!setup.gtm)
    return;

  const tagManagerArgs = {
      gtmId: setup.gtm
  }

  TagManager.initialize(tagManagerArgs)
}

const setIcons = (setup) => {
  document.getElementById("favicon").href = setup.icon ? "/assets/img/"+setup.icon+"_icon.png" : "/assets/img/behome_icon.png";
  const appleTouchIcon = document.createElement('link');
  const head = document.getElementsByTagName("head")[0];

  appleTouchIcon.href =  setup.icon ? "/assets/img/"+setup.icon+"_icon.png" :  "/assets/img/behome_icon.png";
  appleTouchIcon.rel = 'apple-touch-icon';
  head.appendChild(appleTouchIcon);

  if(setup.themeTag)
  {
    const appleSplashScreenIPhone5 = document.createElement('link');
    appleSplashScreenIPhone5.href = "/assets/img/"+setup.themeTag+"/iphone5_splash.png";
    appleSplashScreenIPhone5.media = "(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPhone5.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhone5);

    const appleSplashScreenIPhone6 = document.createElement('link');
    appleSplashScreenIPhone6.href = "/assets/img/"+setup.themeTag+"/iphone6_splash.png";
    appleSplashScreenIPhone6.media = "(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPhone6.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhone6);

    const appleSplashScreenIPhonePlus = document.createElement('link');
    appleSplashScreenIPhonePlus.href = "/assets/img/"+setup.themeTag+"/iphoneplus_splash.png";
    appleSplashScreenIPhonePlus.media = "(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)";
    appleSplashScreenIPhonePlus.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhonePlus);

    const appleSplashScreenIPhoneX = document.createElement('link');
    appleSplashScreenIPhoneX.href = "/assets/img/"+setup.themeTag+"/iphonex_splash.png";
    appleSplashScreenIPhoneX.media = "(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)";
    appleSplashScreenIPhoneX.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhoneX);

    const appleSplashScreenIPhoneXr = document.createElement('link');
    appleSplashScreenIPhoneXr.href = "/assets/img/"+setup.themeTag+"/iphonexr_splash.png";
    appleSplashScreenIPhoneXr.media = "(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPhoneXr.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhoneXr);

    const appleSplashScreenIPhoneXsMax = document.createElement('link');
    appleSplashScreenIPhoneXsMax.href = "/assets/img/"+setup.themeTag+"/iphonexsmax_splash.png";
    appleSplashScreenIPhoneXsMax.media = "(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)";
    appleSplashScreenIPhoneXsMax.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPhoneXsMax);

    const appleSplashScreenIPad = document.createElement('link');
    appleSplashScreenIPad.href = "/assets/img/"+setup.themeTag+"/ipad_splash.png";
    appleSplashScreenIPad.media = "(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPad.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPad);

    const appleSplashScreenIPadPro1 = document.createElement('link');
    appleSplashScreenIPadPro1.href = "/assets/img/"+setup.themeTag+"/ipadpro1_splash.png";
    appleSplashScreenIPadPro1.media = "(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPadPro1.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPadPro1);

    const appleSplashScreenIPadPro3 = document.createElement('link');
    appleSplashScreenIPadPro3.href = "/assets/img/"+setup.themeTag+"/ipadpro3_splash.png";
    appleSplashScreenIPadPro3.media = "(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPadPro3.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPadPro3);

    const appleSplashScreenIPadPro2 = document.createElement('link');
    appleSplashScreenIPadPro2.href = "/assets/img/"+setup.themeTag+"/ipadpro2_splash.png";
    appleSplashScreenIPadPro2.media = "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)";
    appleSplashScreenIPadPro2.rel = 'apple-touch-startup-image';
    head.appendChild(appleSplashScreenIPadPro2);
  }
 
}

const render = async () => {
  const setup = await getSetup();
  setIcons(setup);
  setGTM(setup);
  
  import(`./assets/css/sass/themes/theme-${setup.themeTag ? setup.themeTag : 'behome'}.scss`).then(() => {
    require('./AppRenderer');
  }).catch (e => {
    import(`./assets/css/sass/themes/theme-behome.scss`).then(() => {
      require('./AppRenderer');
    })
  });

};
render();
