import React, { useState, useEffect, useContext } from "react";

import { BASE_URL } from '../constants/services';
import { AuthContext } from '../context/AuthContext'

export const useProperties = () => {
  const [properties, setProperties] = useState(null);

  const { account, user, authFetch } = useContext(AuthContext);

  const getProperty = async () => {
    if (!account) return [];
    try {
      const response = await authFetch(
        BASE_URL +
          "/api/v1/accounts/" +
          account +
          "/residents/" +
          user.id +
          "/properties"
      );
      const data = await response.json();
      setProperties(data);
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (user) getProperty();
  }, [user]);

  return properties;
};
