import React, { useContext, useEffect, useState } from "react";
import { BEHOME_URL } from "../constants/services";
import { useProperties } from "../hooks/use-properties";
import { AuthContext } from "./AuthContext";

/**
 * App Context
 *
 * In this context we manage application information that becomes available after
 * the Authentication of the user is successful.
 */
const AppContext = React.createContext();

const AppDataProvider = (props) => {
  const { account, accountUuid, authFetch, token } = useContext(AuthContext);

  const [state, setState] = useState({
    settings: {},
  });

  const properties = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [leases, setLeases] = useState([]);
  const [isLoadingLeases, setIsLoadingLeases] = useState(false);
  const [isLoadingLease, setIsLoadingLease] = useState(false);
  const [selectedLease, setSelectedLease] = useState(null);

  const getSettings = async () => {
    try {
      const response = await authFetch(
        BEHOME_URL + `/accounts/${account}/residents/settings`,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      setState({
        ...state,
        settings: {
          ...json,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserLeases = async () => {
    setIsLoadingLeases(true);
    try {
      const response = await authFetch(
        BEHOME_URL + `/accounts/${accountUuid}/resident/leases`,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      setLeases(json);
      if (json.length > 0) {
        await getLease(json);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingLeases(false);
    }
  };

  useEffect(() => {
    if (token) {
      getSettings();
      getUserLeases();
    } else {
      setSelectedLease(null);
    }
  }, [token]);

  const getLease = async (leases) => {
    if (selectedLease) return selectedLease;

    const storedSelectedLease = localStorage.getItem("selected_lease") || leases[0].uuid;
    
    if (storedSelectedLease) {
      return setSelectedLease(await getFullLease(storedSelectedLease));
    }

    return null;
  };
  const selectLease = async (lease) => {
    localStorage.setItem("selected_lease", lease.uuid);
    setSelectedLease(await getFullLease(lease.uuid));
  };

  const getFullLease = async (leaseUuid) => {
    setIsLoadingLease(true);
    try {
      const response = await authFetch(
        BEHOME_URL + `/accounts/${accountUuid}/resident/leases/${leaseUuid}`,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingLease(false);
    }
  };

  const getProperty = () => {
    if (selectedLease) return selectedLease.property;
    
    if (selectedProperty) return selectedProperty;

    const storedSelectedProperty = JSON.parse(
      localStorage.getItem("selected_property")
    );
    if (storedSelectedProperty) return storedSelectedProperty;

    return properties && properties.length > 0 && properties[0];
  };

  /**
   * Sets the property in context and remembers the selection in local storage.
   * This is cleared out on sign-out
   * @param {*} property
   */
  const selectProperty = (property) => {
    setSelectedProperty(property);
    localStorage.setItem("selected_property", JSON.stringify(property));
  };

  const refreshSelectedLease = () => {
    getFullLease(selectedLease.uuid);
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        leases,
        selectedLease,
        isLoadingLeases,
        isLoadingLease,
        getLease,
        selectLease,
        selectProperty,
        selectedProperty,
        refreshSelectedLease,
        getProperty,
        properties
      }}
      {...props}
    ></AppContext.Provider>
  );
};

export { AppDataProvider, AppContext };
